* {
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
}
body {
  margin: 0;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.gradient-custom-2 {
  /* fallback for old browsers */
  background: #ceba6d;

  /* Chrome 10-25, Safari 5.1-6 */
  background: -webkit-linear-gradient(to right, #ceba6d, #864f11, #eed757);

  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  background: linear-gradient(to right, #ceba6d, #864f11, #eed757);
}
.gradient-form {
  min-height: 100vh;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}
.input-form {
  border: 2px solid #ceba6d;
  background: transparent;
  color: #ffff;
  padding: 8px 12px;
  border-radius: 8px;
  outline: none;
}
.input-form:focus {
  border: 2px solid #ceba6d;
}
@media (max-width: 400px) {
  .gradient-form {
    flex-direction: column; /* Stack items vertically */
  }

  .gradient-custom-2 {
    border-radius: 0; /* Remove border radius */
    border-top-right-radius: 0.3rem;
    border-bottom-right-radius: 0;
  }

  .gradient-custom-2,
  .text-white {
    width: 100%; /* Make background gradient and text full width */
  }

  .mb-5 {
    margin-bottom: 2rem; /* Increase margin between elements */
  }
}

/* Loading */

.loading-spinner {
  width: 30px;
  height: 30px;
  border: 3px solid transparent;
  border-top-color: #ceba6d;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Loading End*/

/* Default styles */

.box {
  width: 100%;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 100vh;
  color: #ffff;
}
.box .logo {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.box .login-form {
  width: 30%;
  padding: 10px;
}

.box .login-form p {
  color: #d9d7d7;
}

.box .login-form .form-content {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.box .login-form .form-content .input-form {
  border: 2px solid #ceba6d;
  background: transparent;
  color: #ffff;
  padding: 8px 12px;
  border-radius: 8px;
  width: 100%;
}
.submit-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border: none;
  outline: none;
  border-radius: 10px;
  padding: 4px;
  font-weight: bold;
  background-color: rgb(46, 46, 46);
}
/* Add your default styles here */

/* Navbaar Start*/

.navbar {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.navbar .nav-logo {
  width: 5%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.navbar .nav-logo img {
  margin-left: -10px;
  width: 100%;
}

.navbar .nav-logo h3 {
  font-size: 20px;
}

.navbar .logout-button {
  padding: 0px 30px 0px 0px;
  font-size: 18px;
}

.logout-hover:hover {
  color: #ceba6d;
}
/* Navbaar End*/

/* Home Page Start*/

.home-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90vh;
  background-color: #ececec;
}

.home-container .verify-box {
  width: 30%;
  background-color: #ffff;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.home-container .verify-box h3 {
  color: black;
  font-family: sans-serif;
  font-weight: bold;
}

.home-container .verify-box p {
  font-family: sans-serif;
  color: #9d9d9d;
}

.home-container .verify-box h6 {
  font-family: sans-serif;
  color: black;
}

.home-container .verify-box .form-secret {
  width: 100%;
}

.home-container .verify-box .input-secret {
  border: 2px solid #f5f5f5;
  background: #f5f5f5;
  color: #616161;
  padding: 8px 12px;
  border-radius: 18px;
  outline: none;
  width: 100%;
  font-weight: bold;
}

.input-secret::placeholder {
  color: #cccccc;
  font-weight: 100;
}
.home-container .verify-box .verify-btn {
  outline: none;
  border: none;
  background-color: black;
  color: #fff04a;
  font-weight: bold;
  padding: 10px;
  border-radius: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}




.social-login{
  width: 100%;

}
.social-login .social-text{
  text-align: center;
  margin: 30px 0px;

}

.social-login .social-icons{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;
}
.social-login .social-icons .social-button{
  background: black;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  padding: 0px;
  margin: 0px;
  outline: none;
  border: none;
}


.social-login .social-icons .social-button .social-button-img{
  width: 50px;
}

.privacy-policy-container{
  padding: 30px 80px;
}

/* Home Page End*/

/* Media query for screens smaller than 576px (extra small devices) */
@media (max-width: 575.98px) {
  .privacy-policy-container{
    padding: 20px !important;
  }
  .box .login-form {
    width: 90%;
    padding: 10px;
  }
  .logo {
    margin-top: -80px;
  }
  .box .logo img {
    width: 90% !important;
  }
  .navbar .nav-logo {
    width: 33%;
  }
  .navbar .nav-logo img{
    margin-left:-30px;
  }
  .navbar .nav-logo h3 {
    font-size: 17px;
  }
  .navbar .logout-button {
    padding: 0px 20px 0px 0px;
    font-size: 18px;
  }

  .home-container .verify-box {
    margin-top: -100px;
    width: 90%;
  }
  .navbar .logout-button {
    padding: 0px 30px 0px 0px;
    font-size: 20px;
  }
}

/* Media query for screens between 576px and 767.98px (small devices) */
@media (min-width: 576px) and (max-width: 767.98px) {
  .box .login-form {
    width: 70%;
    padding: 10px;
  }
  .box .logo img {
    width: 70% !important;
  }

  .navbar .nav-logo {
    width: 33%;
  }
  .navbar .nav-logo img{
    margin-left:-45px;
  }
  .navbar .nav-logo h3 {
    font-size: 18px;
  }
  .navbar .logout-button {
    padding: 0px 20px 0px 0px;
    font-size: 18px;
  }

  .home-container .verify-box {
    margin-top: -100px;
    width: 90%;
  }
}

/* Media query for screens between 768px and 991.98px (medium devices) */
@media (min-width: 768px) and (max-width: 991.98px) {
  .box .login-form {
    width: 50%;
    padding: 10px;
  }
  .navbar .nav-logo {
    width: 33%;
  }
  .navbar .nav-logo img{
    margin-left:-45px;
  }
  .navbar .nav-logo h3 {
    font-size: 20px;
  }
  .navbar .logout-button {
    padding: 0px 30px 0px 0px;
    font-size: 40px;
  }
  .home-container .verify-box {
    width: 50%;
  }
}

/* Media query for screens between 992px and 1199.98px (large devices) */
@media (min-width: 992px) and (max-width: 1199.98px) {
  .box .login-form {
    width: 40%;
    padding: 10px;
  }
  .navbar .nav-logo {
    width: 30%;
  }
  .navbar .nav-logo h3 {
    font-size: 20px;
  }
  .navbar .logout-button {
    padding: 0px 30px 0px 0px;
    font-size: 18px;
  }
  .home-container .verify-box {
    width: 40%;
  }
}

/* Media query for screens 1200px and larger (extra large devices) */
@media (min-width: 1200px) {
  .box .login-form {
    width: 30%;
    padding: 10px;
  }
  .home-container .verify-box {
    width: 30%;
  }
}
